.filters-navbar {
    position: absolute;
    opacity: 0.7;
    background-color: #fff;
    width: 100%;
    &:is(:focus , :hover){
      opacity: 1;
    }
    &__container {
      display: flex;
      margin: 16px;
      gap: 8px;
    }
  }
  
  .Dropdown-root {
    & > .Dropdown-control {
      border: none;
      color: black;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
    }
  
    & > .Dropdown-menu {
      width: 135px;
      border-radius: 15px;
      font-size: 12px;
    }
  
    .Dropdown-option {
      border-bottom: 1px solid #8080801f;
    }
  
    .Dropdown-placeholder {
      max-width: 202px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  
  .clear-filters {
    cursor: pointer;
    color: hsl(0 89% 48% / 1);
    font-weight: 600;
    font-size: 14px;
  
    &:is(:hover, :focus) {
      text-decoration: underline;
      color: hsl(0, 90%, 39%);
    }
  }
  
  .switch-view {
    position: absolute;
    right: 16px;
  }
  