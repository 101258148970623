.toggle-button {
    * {
      transition: 1s all;
    }
    border: 1px solid #9d833e;
    border-radius: 12px;
    overflow: hidden;
  
    button {
      color: #978e8e;
      padding: 8px 12px;
      border-radius: 12px;
      border: none;
      background: transparent;
      font-size: 14px;
      cursor: pointer;
      &.active {
        background-color: #9d833e;
        color: #fff;
      }
    }
  }
  