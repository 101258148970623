.employee-resignation-container {
    border-radius: 10px;
}

.section-header {
    font-size: 24px;
    /* margin: 10px 0px 10px 0px; */
    font-weight: bold;
    color: #333;
    border-bottom: 2px solid #eee;
    padding: 10px 0px 10px 0px;
}

/* Flexbox for consistent alignment */
.dx-field {
    display: flex;
    align-items: center;
    /* Vertical center alignment */
    margin-bottom: 15px;
    gap: 10px;
    /* Add space between label and field */
}

/* Make sure labels and fields align uniformly */
.dx-field-label {
    flex: 0 0 180px;
    /* Consistent label width */
    text-align: right;
    /* Align text to the right for a neat look */
    margin-right: 10px;
}

.dx-field-value {
    flex: 1;
    max-width: 100%;
    /* Ensure field takes available space */
}

/* Fix HtmlEditor and FileUploader alignment */
.custom-item-wrapper {
    display: flex;
    align-items: center;
    /* Ensure consistent alignment */
}

.submit-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.submit-button {
    margin: 10px;
}