// Define mixins for gradients to reduce repetition
@mixin linear-gradient($color-start, $color-end) {
  background: $color-end; // fallback for old browsers
  background: -webkit-linear-gradient(to bottom,
      $color-start,
      $color-end ); // Chrome 10-25, Safari 5.1-6
  background: linear-gradient(to bottom,
      $color-start,
      $color-end ); // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
}

// Status Classes
.needApproval {
  //@include linear-gradient(#bafffa, #9a9aed);
  background-color: #DFECF7;
}

.approved {
  //@include linear-gradient(#e1fffa, #51d4a8);
  background-color: #DFF7F5;
}

.cancelled {
  background-color: #F2F3F4;
}

.rejected {
  background-color: #F7DFDF;
}

// Wrapped Column Class
.WrappedColumnClass {
  white-space: normal;
  word-wrap: break-word;
  overflow: auto !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

// Base styles for manage headcount
%flex-column {
  display: flex;
  flex-direction: column;
}

.manage-hc,
.manage-headcount {
  @extend %flex-column;
  overflow: auto;
}

// Styles for expanded manage headcount
.manage-headcount-expanded,
.manage-hc-expanded {
  @extend %flex-column;
  overflow-x: hidden;
}

// Add the condition to apply .css-e6tepc based on screen size (fix scroll issue when screen is minimized and then maximized
//@media (min-width: 1200px) {
//
//  .css-e6tepc {
//    width: 1088.96px !important;
//    height: 760.58px !important;
//  }
//
//  // Remove the scroll classes even if .manage-hc is not present
//  .dx-scrollable-native.dx-scrollable-both {
//    overflow-x: hidden;
//
//    > .dx-scrollable-wrapper > .dx-scrollable-container {
//      overflow-x: hidden;
//    }
//  }
//}

// Styles to hide horizontal overflow for scrollable child elements in expanded mode
.manage-headcount-expanded,
.manage-hc-expanded {
  .dx-scrollable-native.dx-scrollable-both {
    overflow-x: hidden;

    >.dx-scrollable-wrapper>.dx-scrollable-container {
      overflow-x: hidden;
    }
  }
}

// Styles to allow horizontal overflow for scrollable child elements
.manage-hc {
  .dx-scrollable-native.dx-scrollable-both {
    overflow-x: auto;

    >.dx-scrollable-wrapper>.dx-scrollable-container {
      overflow-x: auto;
    }
  }
}

.manage-headcount {
  &:has(.manage-hc-expanded .dx-form) {
    .dx-scrollable-native.dx-scrollable-both {
      overflow-x: hidden;

      >.dx-scrollable-wrapper>.dx-scrollable-container {
        overflow-x: hidden;
      }
    }
  }
}

.manage-headcount-expanded {
  &:has(.manage-hc-expanded .dx-form) {
    .dx-scrollable-native.dx-scrollable-both {
      overflow-x: hidden;

      >.dx-scrollable-wrapper>.dx-scrollable-container {
        overflow-x: hidden;
      }
    }
  }

  &:has(.hc_leaver_details) {
    .dx-scrollable-native.dx-scrollable-both {
      overflow-x: auto;

      >.dx-scrollable-wrapper>.dx-scrollable-container {
        overflow-x: auto;
      }
    }
  }
}

// Styles for details view and forms within expanded manage headcount
.manage-hc-expanded .dx-form,
.manage-headcount-expanded .dx-form {
  max-width: calc(100vw - 8%);
  padding: 4px;
  overflow-x: auto;
}

// Styles for hc_replacement class
.hc_replacement {
  max-width: calc(100vw - 8%);
  overflow-x: auto;
  padding: 4px;
  background: white;
}

// Styles for datagrid form buttons container
.dx-datagrid-form-buttons-container {
  float: none;
  text-align: right;
  max-width: calc(100vw - 8%);
}

.d-flex {
  display: flex;
}

.del-icon .dx-button-content {
  padding: 1px !important;
}

.dx-datagrid-group-opened {
  color: #1890ff
}

.dx-datagrid-group-closed {
  color: #1890ff
}

.employee-field-message {
  text-wrap: pretty !important;
  font-size: 12px !important;
  font-style: italic !important;
}

.swal-text-wo-icon {
  padding: 15px !important;
  font-size: 14px !important;
  line-height: 1.6 !important;
}


.swal-text {
  font-size: 14px !important;
}

.swal-button {
  font-size: 14px !important;
}

.swal-actions {
  margin: 0 !important;
}