.chart-card {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-inline: 16px;
  height: 100vh;
  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    box-shadow: 0 3px 10px 3px rgba(33, 33, 41, 0.05);
    border-radius: 25px;
    background: hsl(0, 0%, 98.9%);
    position: relative;
    margin-block: 16px;
    overflow: hidden;
  }
}

@media (min-width: 1200px) {
  .chart-card {
    margin-left: 17.225rem;
  }
}

body:has(.chart-card) {
  position: relative;
  & > #root,
  & > #root > div {
    height: 100%;
  }
}
