/* CSS styling to display select/multi-select dropdown list based on content */
.dx-popup-content .dx-list-item {
  display: block;
}

/* set opacity for all read-only text columns (used in HC) */
.dx-texteditor.dx-state-readonly {
  border-style: double;
  opacity: .5;
}

/* set opacity for read-only all checkbox (used in HC */
.dx-checkbox.dx-state-readonly {
  cursor: default;
  opacity: .5;
}

/* Datagrid - make the content middle vertical alignment */
.dx-datagrid-content .dx-datagrid-table .dx-row>td,
.dx-datagrid-content .dx-datagrid-table .dx-row>tr>td {
  vertical-align: middle;
}

/* Datagrid HC - fix the attachments column height issue */
.dx-datagrid-content .dx-datagrid-table .dx-row>td>ul,
.dx-datagrid-content .dx-datagrid-table .dx-row>tr>td>ul {
  padding-bottom: 0;
}

/* Datagrid - fix the paragraph margin to be middle vertical alignment */
.dx-datagrid-content .dx-datagrid-table .dx-row>td>p,
.dx-datagrid-content .dx-datagrid-table .dx-row>tr>td>p {
  margin: 0.2rem 0;
}

/* Datagrid - make the master detail expand arrow middle vertical alignment */
.dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-datagrid-group-space,
.dx-datagrid-content .dx-datagrid-table .dx-row>tr>td.dx-datagrid-group-space {
  vertical-align: middle;
  padding-top: 0;
}

/* Datagrid Form Label vertical alignment */
.dx-form .dx-layout-manager .dx-label-h-align.dx-flex-layout:not(.dx-field-item-label-align) {
  align-items: flex-start;
}

.dx-field-item-content .dx-field-item-content-location-right {
  text-wrap: balance;
}

/* Datagrid Form Label vertical alignment fix */
.dx-form .dx-layout-manager .dx-label-h-align .dx-field-item-label {
  padding-top: 8px;
}

/* Headcounts DataGrid Styles -- Start */

/* Hc DataGrid Form hide column names (+ Leaver Form) */
#HcDataGrid.HcDataGridForm .dx-datagrid-headers,
#HcLeaverDataGrid.HcLeaverDataGridForm .dx-datagrid-headers {
  display: none;
}

/* Hc DataGrid Form fix jumping issue - set default height to the form  */
#HcDataGrid.HcDataGridForm .dx-datagrid-rowsview .dx-datagrid-content {
  min-height: 783px;
}

/* Hc DataGrid Form remove the inner border */
#HcDataGrid.HcDataGridForm .dx-datagrid-rowsview .dx-datagrid-content .dx-master-detail-cell {
  border: none;
}

/* Hc DataGrid Leaver Form fix jumping issue - set default height to the form */
#HcLeaverDataGrid.HcLeaverDataGridForm .dx-datagrid-rowsview .dx-datagrid-content {
  min-height: 666px;
}

/* Hc DataGrid Leaver Form remove the inner border */
#HcLeaverDataGrid.HcLeaverDataGridForm .dx-datagrid-rowsview .dx-datagrid-content .dx-master-detail-cell {
  border: none;
}

/* Hc DataGrid Form fix jumping issue - set default height to the attachment area (+ Leaver Form) */
#HcDataGrid.HcDataGridForm .dx-datagrid-rowsview .dx-datagrid-content .filepond--wrapper,
#HcLeaverDataGrid.HcLeaverDataGridForm .dx-datagrid-rowsview .dx-datagrid-content .filepond--wrapper {
  min-height: 78px;
}

/* Hc DataGrid Form fix error message comes vertical issue (+ Leaver Form) */
#HcDataGrid.HcDataGridForm .dx-invalid-message.dx-overlay-wrapper,
#HcLeaverDataGrid.HcLeaverDataGridForm .dx-invalid-message.dx-overlay-wrapper {
  min-width: 100%;
}

#HcFormDetails {
  min-height: 420px;
}

#HcLeaverFormDetails {
  min-height: 320px;
}

#all-hc-data-grid .form-error {
  background-color: #e89895;
  white-space: normal;
  word-wrap: break-word;
  padding: 7px 35px 7px 7px;
  color: white;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 16px;
}

#HcDataGrid .textAreaColumnWrap,
#HcLeaverDataGrid .textAreaColumnWrap {
  white-space: break-spaces;
  word-wrap: normal;
}

/* Hc details accordion */
.HcDetailsAccordionDetails {
  padding: 0 !important;
}

/* Hc details accordion */
.TransferDetailsAccordionDetails {
  padding: 0 !important;
}

/* Hide Safari auto-fill icon */
#HcDataGrid.HcDataGridForm input::-webkit-contacts-auto-fill-button,
#HcLeaverDataGrid.HcLeaverDataGridForm input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

/* Headcounts DataGrid Styles -- End */

/* New Hc View Forms -- Start */
#HcForm {
  min-height: 550px;
}

#HcLeaverForm {
  min-height: 580px;
}

#HcFormContainer .form-error,
#HcLeaverFormContainer .form-error {
  background-color: #e89895;
  white-space: normal;
  word-wrap: break-word;
  padding: 7px 35px 7px 7px;
  color: white;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 16px;
}

#TransferFormContainer .form-error {
  background-color: #e89895;
  white-space: normal;
  word-wrap: break-word;
  padding: 7px 35px 7px 7px;
  color: white;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.HcFormContent span.dx-field-item-label-content,
.HcLeaverFormContent span.dx-field-item-label-content {
  width: 115px !important;
  white-space: initial !important;
}

/* fileuploader styles -- Start */
.HcFormContent .dx-fileuploader-wrapper,
.HcLeaverFormContent .dx-fileuploader-wrapper {
  padding: 7px 0 !important;
}

.HcFormContent .dx-fileuploader-wrapper .dx-fileuploader-content>.dx-fileuploader-upload-button,
.HcLeaverFormContent .dx-fileuploader-wrapper .dx-fileuploader-content>.dx-fileuploader-upload-button {
  display: none !important;
}

.HcFormContent .dx-fileuploader-show-file-list .dx-fileuploader-files-container,
.HcLeaverFormContent .dx-fileuploader-show-file-list .dx-fileuploader-files-container {
  padding-top: 10px !important;
}

.HcFormContent .dx-fileuploader-show-file-list .dx-fileuploader-files-container .dx-fileuploader-file-container .dx-fileuploader-button-container:nth-child(2),
.HcLeaverFormContent .dx-fileuploader-show-file-list .dx-fileuploader-files-container .dx-fileuploader-file-container .dx-fileuploader-button-container:nth-child(2) {
  display: none !important;
}

/* fileuploader styles -- End */

/* New Hc View Forms -- End */

/* Manage Client Vital DataGrid Styles -- Start */
#ManageCVGrid .invalid-cell {
  border: 1px solid red;
  outline: #cc2929;
  outline-width: medium;
  outline-style: auto;
}

#ManageCVGrid .dx-datagrid-headers .dx-datagrid-content {
  display: none;
}

#ManageCVGrid .dx-datagrid-headers .dx-datagrid-content:first-child {
  display: block;
}

#ManageCVGrid .dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell {
  padding: 7px;
}

#ManageCVGrid .dx-radiogroup-horizontal .dx-radiobutton {
  margin-right: 0;
}

#ManageCVGrid .dx-radio-value-container {
  padding-left: 14px;
  padding-right: 10px;
}

button.categoryItemTooltip {
  vertical-align: sub;
  padding: 0 6px;
  color: #337ab7;
  font-size: 1.2rem;
}

#ViewCVGrid .dx-datagrid-rowsview .dx-row.dx-row-lines,
#ViewCVGrid .dx-datagrid-rowsview .dx-row.dx-row-lines>td,
#ViewCVGrid .dx-datagrid-rowsview .dx-row.dx-row-lines>tr>td {
  text-wrap: balance;
  border-top: none;
}


/* Manage Client Vital DataGrid Styles -- End */

/* set opacity for read-only file attachment (used in HC/leaver) */
.filepond--root[data-disabled] {
  pointer-events: none;
  opacity: .5;
}

/* hide credits for file attachment */
.filepond--root .filepond--credits[style] {
  font-size: 0;
  color: transparent;
}

.rc-steps-item-finish .rc-steps-item-tail:after {
  background-color: #00B0A3;
}

.rc-steps-item-finish .rc-steps-item-icon {
  border-color: #00B0A3;
  background-color: #00B0A3;
}

.rc-steps-item-process .rc-steps-item-icon {
  background: #16ABE0;
  border-color: #16ABE0;
}

/* Manage Client Vitals Datagrid Custom styles -- Start */
.cvItemsContainer .cvItem {
  display: flex;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 6px;
  overflow: hidden;
  white-space: pre-wrap;
}

.cvItemsContainer .cvItem:last-child {
  margin-bottom: 0;
}

.cvItemsContainer .cvItem .itemName {
  color: #333;
}

.cvItemsContainer .cvItem:hover {
  background: #eee;
}

/* .cvItemsContainer .cvItem .itemLights {
  display: flex;
  padding-top: 5px;
} */

/* .cvItemsContainer .cvItem .itemLights .light {
  margin-right: 5px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
} */

#ClientVitalsDataGrid .dx-datagrid-content .dx-datagrid-table .dx-row>td,
#ClientVitalsDataGrid .dx-datagrid-content .dx-datagrid-table .dx-row>tr>td {
  vertical-align: top;
}

/* Manage Client Vitals Datagrid Custom styles -- End */

/* Custom Datagrid Table styles -- Start */
.CustomDatagridTable p {
  font-size: 15px;
  font-weight: 400;
}

.CustomDatagridTable table {
  text-align: left;
  table-layout: fixed;
  width: 100%;
  /* Ensure table takes full width */
}

.CustomDatagridTable table tbody tr:nth-child(odd) {
  background: #FFFFFF;
}

.CustomDatagridTable table tbody tr:nth-child(even) {
  background: #f5f5f5;
}

.CustomDatagridTable table thead {
  border-bottom: solid 2px #dddddd;
}

.CustomDatagridTable table thead th,
.CustomDatagridTable table tbody td {
  text-align: left;
  border: solid 1px #dddddd;
  padding: 7px;
  overflow: hidden;
  /* Prevent overflow */
  text-overflow: ellipsis;
  /* Show ellipsis if text is too long */
  white-space: nowrap;
  /* Keep text on a single line */
}

.CustomDatagridTable table thead th {
  font-weight: 400;
  color: #959595;
}

.CustomDatagridTable table tbody td.comment-cell {
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 400px;
  overflow: hidden;
}

.CustomDatagridTable table tbody td.comment-cell.scrollable {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow-y: auto;
  height: calc(1.5em * 7);
  line-height: 1.5em;
  word-break: break-all;
}


.HcHistoryTransactionTable.CustomDatagridTable {
  margin-top: 20px;
}

.HcHistoryTransactionTable.CustomDatagridTable table {
  width: 100%;
}

/* Custom Datagrid Table styles -- End */