.rc-steps.rc-steps-label-vertical .rc-steps-item-content {
    display: block;
    text-align: center;
    margin-top: 6px;
    width: 200px;
    position: relative;
    left: -78px;
}

.rc-steps {
    padding-top: 16px;
    padding-left: 78px;
}

.rc-steps.overflow-steps {
    padding: 20px 0 10px 78px;
    overflow: auto;
}

.rc-steps .rc-steps-item-title {
    width: 250px;
    text-align: center;
}

.rc-steps .rc-steps-item-description {
    max-height: 50px;
    overflow-y: auto;
    padding: 9.5px;
    margin-top: 14px;
    text-wrap: initial;
    text-align: center;
}

.rc-steps .rc-steps-item.rc-steps-item-finish .rc-steps-item-description {
    max-height: 50px;
    overflow-y: auto;
    padding: 9.5px;
    margin-top: 14px;
}

.rc-steps .rc-steps-item-subtitle {
    width: 250px;
    position: absolute;
    top: -56px;
    left: 0;
    margin: 0;
}

.rc-steps .rc-steps-item-approvername {
    font-size: 13px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.43);
    position: absolute;
    top: 40px;
    left: -127px;
    width: 250px;
    text-align: center;
}

.rc-steps .rc-steps-item .rc-steps-item-description {
    width: 250px;
}

.rc-steps .rc-steps-item.rc-steps-item-finish .rc-steps-item-description {
    margin-top: 14px;
}

.rc-steps .rc-steps-item.rc-steps-item-active:last-child .rc-steps-item-tail::after {
    display: none;
}

.rc-steps-item.rc-steps-item-finish:last-child .rc-steps-item-tail {
    display: block;
}

.rc-steps-item.rc-steps-item-process:last-child .rc-steps-item-tail {
    display: ruby;
}

.rc-steps-item.rc-steps-item-finish:last-child .rc-steps-item-tail::after {
    display: none;
}
 
.rc-steps-item.rc-steps-item-wait:last-child .rc-steps-item-tail::after {
    display: none;
}
.rc-steps-item.rc-steps-item-wait:last-child .rc-steps-item-tail {
    display: block;
}
 
