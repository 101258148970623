.ant-modal-mask {
  background-color: rgb(0 0 0 / 70%);
}

.ant-table-content {
  overflow: auto hidden;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.antd-table-custom-class thead th,
.antd-table-custom-class tbody td {
  white-space: pre-wrap;
  text-overflow: ellipsis
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.Mui-disabled {
  cursor: 'not-allowed !important'
}

.ant-select-selection-item {
  font-weight: 300;
}

.dropdown-content {
  z-index: 2 !important;
}

.MuiTabs-root .MuiTabs-flexContainer .MuiTab-root {
  flex-grow: 0;
  padding: 0.2rem 3rem;
}

/* .ant-btn,
.ant-btn-default,
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  background: #16ABE0 !important;
  border: none;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  min-height: 2.5rem;
  padding: 0.625rem 1.5rem;
} */

/* .ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  background: #16ABE0 !important;
  color: #fff !important;
} */


/* .ant-table-cell-ellipsis {
  overflow: overlay;
  white-space: break-spaces;
  text-overflow: ellipsis;
  word-break: keep-all;
} */