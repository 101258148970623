.manage-headcount {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__title {
        flex: 1;
    }

    &__toggle-group {
        width: 100%;
        padding: 1rem;
        margin-bottom: 16px;
        border-radius: 12px;
        flex-direction: column;
        gap: 8px;

        &-container {
            display: flex;
            gap: 16px;
            width: 100%;
        }

        &,
        &-button {
            display: flex;
        }

        &-button {
            flex: 4;
            flex-wrap: wrap;
        }
    }

    .MuiToggleButtonGroup-root {
        border-radius: 12px;
    }

    .MuiToggleButton-root {
        padding: 9px 12px;
        border-radius: 12px;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

        &:hover {
            background-color: rgba(157, 131, 62, 0.1);
        }

        &.Mui-selected {
            background-color: rgb(157 131 62);
            color: #fff;

            &:hover {
                background-color: rgb(157 131 62);
            }
        }
    }
}