.filters-container {
  display: flex;
  border: 1px solid #dddddd;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  flex-direction: column;
  margin-top: 2em;
}

.header-filters {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;

  &,
  &__select {
    width: 100%;
  }
}

.lights-legend {
  &__container {
    background: white;
    display: flex;
    gap: 12px;
    margin-block: 30px;
    margin-bottom: 12px;
    width: 100%;
    overflow: visible;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    padding: 6px 12px;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    position: relative;

    cursor: pointer;

    &:is(:hover) {
      filter: drop-shadow(1px 1px 1px #2a28283d);
      font-weight: bold;
    }

    &--active {
      font-weight: bold;
      background: #2220201f;
      border-radius: 8px;
      box-shadow: 1px 0px 2px #625e5e4f;
      -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }

  &__color {
    width: 20px;
    border-radius: 4px;
    height: 20px;
    margin-right: 8px;
  }
}

.cv-grid {
  //margin-right: 30px;

  .dx-datagrid-header-panel {
    position: absolute;
    top: -164px;
    right: 0px;
  }
}

.filter-margin-top-10 {
  margin: 10px 0px 0px 0px !important;
}

.MuiGrid-container {
  div.padding-left-10 {
    padding-left: 10px;
  }
}

.custom-cell-content {
  display: inline-block;
  overflow: hidden;
  word-break: break-word;
}

.custom-cell-content.scrollable {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  height: calc(1.5em * 7);
  line-height: 1.5em;
  overflow-y: auto;
  word-break: break-all;
  word-wrap: break-word;
}