div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
    height: 100%;
    background: white;
}

div.DraftEditor-root {
    border: 1px solid #f0f1f1;
    background-color: white !important;
    padding: 10px 10px;
    opacity: 1 !important;
    pointer-events: all !important;
    height: 200px;
    overflow-y: scroll;
}

div.DraftEditor-root {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

div.DraftEditor-root::-webkit-scrollbar {
    display: none;
}