.sidenav-main-container {
    padding-bottom: 0.5rem !important;
}

.sidenav-main-container>.sidenav-item {
    margin: 0 0.4rem;
}

.sidenav-item {
    display: flex;
    list-style-type: none;
}

.sidenav-item .sidenav-item-content {
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

.sidenav-item .sidenav-item-title>.collapse-arrow {
    position: absolute;
    right: 0.3rem;
    top: calc(50% - 10px);
    font-size: 1.2rem !important;
    color: white;
    opacity: 0.5;
    transition: transform 0.25s;
}

.sidenav-item.open>.sidenav-item-content>.sidenav-item-title>.collapse-arrow {
    transform: rotate(180deg);
    opacity: 1;
}

.sidenav-item.open>.sidenav-item-content>.sidenav-nested-items {
    max-height: 50rem;
    transition: max-height 0.8s;
}

.sidenav-item .sidenav-item-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: break-spaces;
    position: relative;
    padding: 0.8rem 1rem;
    margin: 0.1rem 0;
    color: white;
    font-weight: 400;
    font-size: 0.875rem;
    border-radius: 0.375rem;
    transition: background-color 0.25s;
}

.sidenav-item.active>.sidenav-item-content>.sidenav-item-title,
a.sidenav-item-content.active>.sidenav-item-title {
    background-color: rgb(22, 171, 224);
}

.sidenav-item.active>.sidenav-item-content,
a.sidenav-item-content.active {
    pointer-events: none;
}

.sidenav-item .sidenav-item-content:not(.active)>.sidenav-item-title:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.sidenav-item .sidenav-item-title>svg:not(.collapse-arrow) {
    color: white;
    margin-inline-end: 0.5rem;
    font-size: 1.25rem !important;
}

.sidenav-item .sidenav-nested-items {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.35s;
}

.sidenav-item .sidenav-nested-items .sidenav-item-title {
    padding-inline-start: 2.8rem;
}

.sidenav-item .sidenav-nested-items .sidenav-nested-items .sidenav-item-title {
    padding-inline-start: 3.6rem;
}

.sidenav-item .sidenav-nested-items .sidenav-nested-items .sidenav-nested-items .sidenav-item-title {
    padding-inline-start: 4.8rem;
}

.sidenav-item .sidenav-nested-items .sidenav-nested-items .sidenav-nested-items .sidenav-nested-items .sidenav-item-title {
    padding-inline-start: 5.8rem;
}

.sidenav-item .sidenav-nested-items .sidenav-nested-items .sidenav-nested-items .sidenav-nested-items .sidenav-nested-items .sidenav-item-title {
    padding-inline-start: 6.8rem;
}

.sidenav-item .sidenav-nested-items .sidenav-nested-items .sidenav-nested-items .sidenav-nested-items .sidenav-nested-items .sidenav-nested-items .sidenav-item-title {
    padding-inline-start: 7.8rem;
}

/* Sidenav Custom Scrollbar */
.sidenav-wrapper .MuiPaper-root {
    overflow: hidden !important;
}

.sidenav-wrapper .sidenav-main-container {
    overflow-y: auto;
    margin: 0 0.2rem;
}

/* width */
.sidenav-wrapper .sidenav-main-container::-webkit-scrollbar {
    width: 4px;
}

/* Handle */
.sidenav-wrapper .sidenav-main-container::-webkit-scrollbar-thumb {
    background: #767676;
    border-radius: 0.75rem;
}

/* Handle on hover */
.sidenav-wrapper .sidenav-main-container::-webkit-scrollbar-thumb:hover {
    background: #898989;
}

/* fix sidenav height to fit the screen in resolution zoom cases */
@media screen and (max-width: 1440px) and (min-width: 1367px) {
    .sidenav-wrapper .MuiDrawer-paper {
        height: calc(106vh - 2rem);
    }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
    .sidenav-wrapper .MuiDrawer-paper {
        height: calc(118vh - 2rem);
    }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
    .sidenav-wrapper .MuiDrawer-paper {
        height: calc(125vh - 2rem);
    }
}