.filters-container {
    display: flex;
    border: 1px solid #dddddd;
    padding: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    flex-direction: column;
    margin-top: 2em;
}

.header-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    &,
    &__select {
        width: 100%;
    }
}

.lights-legend {
    &__container {
        background: white;
        display: flex;
        gap: 12px;
        margin-block: 30px;
        margin-bottom: 12px;
        width: 100%;
        overflow: auto;
    }

    &__item {
        display: flex;
        align-items: flex-start;
        font-size: 14px;
        padding: 6px 12px;
        -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        position: relative;

        cursor: pointer;

        &:is(:hover) {
            filter: drop-shadow(1px 1px 1px #2a28283d);
            font-weight: bold;
        }

        &--active {
            font-weight: bold;
            background: #2220201f;
            border-radius: 8px;
            box-shadow: 1px 0px 2px #625e5e4f;
            -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
            transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        }
    }

    &__color {
        border: 1px solid #A2A2A2;
        width: 20px;
        border-radius: 4px;
        height: 20px;
        margin-right: 8px;
    }
}

.dx-datagrid-rowsview .dx-master-detail-row > .dx-master-detail-cell:has(.disabled-padding)
{
    padding: 20px 0px;
}