.public-DraftEditor-content {
    cursor: 'not-allowed !important';
    opacity: '0.6 !important', ;
    pointer-events: 'all !important';
}

.input-error {
    border-color: #e63946;
}

.error {
    color: #e63946;
    font-size: 0.8rem;
    margin-top: 0.3rem;
}


.toPrint {
    display: none;
    page-break-inside: avoid;
}

body>.toPrint {
    display: block;
    page-break-inside: avoid;
}

@media print {
    .toPrint {
        display: block;
        page-break-inside: avoid;
    }

    pre,
    blockquote {
        page-break-inside: avoid;
    }
}

.toPrint {
    flex: 1;
    page-break-inside: avoid;
}

/* Job Summary custom styles */
.jd-container .MuiBox-root ul,
.jd-container .MuiBox-root ol {
    padding: inherit;
}

.jd-container .MuiBox-root p {
    margin: 0.25rem 0;
}

.jd-container .MuiBox-root .rdw-editor-wrapper .DraftEditor-root .public-DraftStyleDefault-block,
.jd-container .MuiBox-root .rdw-editor-wrapper .DraftEditor-root .public-DraftStyleDefault-ul,
.jd-container .MuiBox-root .rdw-editor-wrapper .DraftEditor-root .public-DraftStyleDefault-ol {
    margin: 0 0 0.1rem 0;
}

.jd-container .custom-text-field .MuiInput-input {
    padding: 9px 10px;
}

.jd-container .custom-text-field .MuiInput-input::placeholder {
    color: #000000;
}

.jd-container .custom-text-field:not(.disabled-input) .MuiInput-input {
    color: #000000;
}

.jd-container .custom-text-field .MuiInput-underline:after {
    border-color: #3B8EFF;
}

.jd-container .custom-text-field.disabled-input .MuiInput-root {
    background: #f2f2f2;
}

.jd-container .custom-text-field.disabled-input .MuiInput-root:before {
    border-color: #e6e6e6;
}

.jd-container .MuiBox-root .MuiGrid-item .MuiTypography-root>i {
    font-weight: 300;
}