.contact-modal {
	position: absolute;
	right: 16px;
	top: 10%;
	&__wrapper {
		border-radius: 25px;
		width: 402px;
		background-color: #fdfffc;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		transition: all 0.3s ease-in-out;
		color: #212129;
		min-height: 230px;
		box-shadow: 0 3px 10px 3px rgba(33, 33, 41, 0.05);
		position: relative;
		transition-delay: 1.7s;
	}

	&__title {
		margin-top: 0;
	}

	&__body {
		max-height: 360px;
		overflow-y: auto;
	}

	&--close {
		position: absolute;
		background: none;
		border: none;
		right: 14px;
		top: 12px;
		cursor: pointer;
		font-size: 14px;
		font-family: cursive;

		&:hover {
			font-weight: bold;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		margin: 36px;
		max-height: 360px;
		overflow-y: auto;
	}
}
.contact-info {
	display: flex;
	flex-direction: column;
	gap: 16px;
	font-size: 16px;

	span {
		font-weight: 600;
		margin-right: 4px;
	}

	label {
		text-overflow: ellipsis;
		max-width: 100%;
		overflow: hidden;
	}
}
