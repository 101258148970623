@font-face {
  font-family: 'GothamNarrow';
  src: local('GothamNarrow'), url('assets/fonts/GothamNarrow-Book.otf') format('truetype');
}

.__toggle__btn__narrow__height__ > .ant-switch-handle {
  top: -5px;
  left: -1px;
}
.__toggle__btn__narrow__height__.ant-switch-checked > .ant-switch-handle {
  left: calc(100% - 18px);
}
.__toggle__btn__narrow__height__ > .ant-switch-handle::before {
  background-color: #a9a9a9;
}
