.combination-item {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 15px;
  box-shadow: 2px 1px 8px #cccccc87;
  margin-bottom: 12px;
  position: relative;
}
.combination-item--edit {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.combination-item--edit > div {
  font-size: 16px;
  flex: 1 0 40%;
  flex-wrap: wrap;
  display: flex;
  gap: 4px;
}

.combination-item__footer {
  display: flex;
  gap: 4px;
}

.combination-item--close {
  position: absolute;
  right: 12px;
  top: 6px;
}

.combination-item--close button {
  color: #333333;
}

.combination-item--edit > div > span {
  font-weight: bold;
}
.combination-item__edit-btn {
  position: absolute;
  top: -24px;
  right: 0;
}
.react-checkbox-tree label {
  display: flex;
}
.react-checkbox-tree label svg {
  fill: #296293;
}

.loading__view {
  backdrop-filter: blur(2px);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
