.org-chart {
  width: 100%;
  height: 100vh;

  &__container {
    background: #fff;
    border-radius: 12px;
    width: 100px;
    height: 100px;
    display: flex;
    color: black;
    font-size: 12px;
    padding: 0px 8px;
    position: relative;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    align-self: center;
    &--expanded {
      animation: fadeBackground 3s forwards ease-in-out;
    }
  }

  &__collapse {
    position: absolute;
    bottom: -25px;
    right: 37%;
    background: #fff;
    box-shadow: 0px 1px 3px #3a372e52;
    border-radius: 8px;
    color: #000;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: monospace;
    &:hover {
      font-weight: bold;
    }
  }
  svg > g {
    stroke-width: 1px;
  }
  .rd3t-link {
    fill: none;
    stroke: #e7e7e7;
  }
}

@keyframes fadeBackground {
  0% {
    background-color: rgb(157, 131, 62);
    filter: blur(0.7px);
  }
  50% {
    filter: blur(0.4px);
  }
  100% {
    background-color: white;
    filter: blur(0);
  }
}
