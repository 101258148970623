.chip {
  &__list {
    display: flex;
    gap: 8px 4px;
    flex-wrap: wrap;
    max-height: 108px;
    overflow: auto;
    border-radius: 12px;
    box-shadow: 0px 1px 2px rgba(221, 221, 221, 0.6);
    padding: 8px;
  }
  &__item {
    position: relative;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    appearance: none;
    max-width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    background-color: #b3e5fc;
    border-radius: 16px;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    outline: 0px;
    text-decoration: none;
    border: 0px;
    padding: 0px;
    vertical-align: middle;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 12px;
    padding-right: 12px;
    white-space: nowrap;
    color: black;
    margin-top: 2px;
    &:is(:hover, :active) {
      box-shadow: 1px 1px 2px 0px #7472729c;
    }
  }
}

.email-subject {
  &__label {
    color: #333333;
    font-size: 14px;
    font-weight: 400;
  }
  &__input {
    width: 100%;
    display: block;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 8px;
    white-space: break-spaces;
    &:empty:not(:focus):before {
      content: attr(placeholder);
      color: grey;
      font-style: italic;
    }
    &,
    &:focus-visible {
      outline: none;
    }
  }
}
