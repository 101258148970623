.tree-chart {
  svg > g {
    stroke-width: 2;
  }
}

.node-non-selected > circle,
.node-non-selected > text {
  fill: #998e8e;
  filter: blur(2px) contrast(0.5);
  r: 12;
  stroke: #b3aaaa;
  font-weight: 400;
  font-size: 12px;
}

.link-non-selected {
  filter: opacity(0.5) blur(2px);
}

.link-label {
  stroke-width: 0.4;
}

circle,
.node {
  cursor: pointer;
  &:focus {
    outline: none;
    stroke: #fff5f5;
    stroke-width: 4px;
    stroke-dasharray: 2px;
  }
}

.tippy-tooltip {
  background: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 10px;
}
